import Executive from '../assets/images/efficiency.svg';
import CostEfficiency from '../assets/images/executive.svg';
import Flexible from '../assets/images/flexible.svg';

function Benefits() {
  return (
    <section className='who-we-are ptb-80 bg-000000' id='benefits'>
      <div className='container'>
        <div className='section-title'>
          <h4 className='theme-character-style-3'>Your</h4>
          <h2 className='theme-character-style-7 mb-3'>Benefits</h2>
        </div>

        <div className='row'>
          <div className='col-lg-4 col-md-6 text-center mt-4'>
            <div className='align-items-center d-flex pe-4 flex-column flex-grow-1'>
              <img src={CostEfficiency} alt='abstract cycling arrows' />
              <h4
                className='theme-character-style-1 my-4'
                style={{ minHeight: '80px' }}
              >
                A smart investment
              </h4>
              <p className='text-center theme-character-style-3'>
                Save your business costs by leveraging the flexibility and
                effectiveness of hiring a CTO on-demand. Simple pricing without
                recruitment fees, nor bonuses, or long-standing obligations.
              </p>
            </div>
          </div>

          <div className='col-lg-4 col-md-6 text-center mt-4'>
            <div className='align-items-center d-flex pe-4 flex-column flex-grow-1'>
              <img src={Executive} alt='abstract hierarchy' />
              <h4
                className='theme-character-style-1 my-4'
                style={{ minHeight: '80px' }}
              >
                Tailored to your needs
              </h4>
              <p className='text-center theme-character-style-3'>
                Opt for the most suitable engagement model that will work best
                for your specific business needs, ever-evolving goals and
                timeline, and will support your technology transformation
                initiative.
              </p>
            </div>
          </div>

          <div className='col-lg-4 col-md-6 text-center mt-4'>
            <div className='align-items-center d-flex pe-4 flex-column flex-grow-1'>
              <img src={Flexible} alt='abstract crossroads' />
              <h4
                className='theme-character-style-1 my-4'
                style={{ minHeight: '80px' }}
              >
                C-suite expertise
              </h4>
              <p className='text-center theme-character-style-3'>
                Leverage the expertise of executive-level managers, gain
                insights on complex challenges, and navigate the tech maze with
                solid strategic decisions regardless of the size of your
                company.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Benefits;
