import React from 'react';

function SingleCapability(props: {
  icon: string;
  children: React.ReactNode;
  title: string;
}) {
  const { icon, title, children } = props;

  return (
    <div className='row justify-content-center align-items-start'>
      <div className='col-xl-2 col-lg-3 col-md-12 col-sm-12'>
        <img src={icon} alt='abstract arrow moving between points' />
      </div>
      <div className='col-xl-10 col-lg-9 col-md-12 col-sm-12'>
        <div>
          <h4 className='theme-character-style-1'>{title}</h4>
          <p className='theme-character-style-3 my-4'>{children}</p>
        </div>
      </div>
    </div>
  );
}

export default SingleCapability;
