import DueDiligence from '../assets/images/due_diligence.svg';
import EnterpriseArchitecture from '../assets/images/enterprise.svg';
import Performance from '../assets/images/performance.svg';
import Providers from '../assets/images/providers.svg';
import Sourcing from '../assets/images/sourcing.svg';
import Strategy from '../assets/images/strategy.svg';
import SingleCapability from './SingleCapability';

function Capabilities() {
  return (
    <section
      className='who-we-are ptb-80 bg-000000 capabilities'
      id='capabilities'
    >
      <div className='container'>
        <div className='section-title'>
          <h4 className='theme-character-style-3'>Our</h4>
          <h2 className='theme-character-style-7 mb-3'>Capabilities</h2>
        </div>

        <div className='container'>
          <div className='row gy-3'>
            <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
              <SingleCapability icon={Strategy} title='Strategy and Roadmap'>
                Develop and advise on strategic planning by gaining deep insight
                into the starting position and combining this with a perspective
                on the future. Build a technology roadmap tailored to the
                business goals, market environment, and available resources.
              </SingleCapability>
            </div>

            <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
              <SingleCapability
                icon={EnterpriseArchitecture}
                title='Enterprise Architecture'
              >
                Analyze and manage the execution of business transformation and
                innovation initiatives to ensure the business vision, IT
                strategy, action plan, and processes are aligned and optimized.
              </SingleCapability>
            </div>

            <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
              <SingleCapability
                icon={DueDiligence}
                title='Technology Due Diligence'
              >
                Conduct a comprehensive risk analysis and technology assessment
                using criteria such as IT strategy, architecture and
                infrastructure, products/services, technology roadmap, IT
                processes, and security.
              </SingleCapability>
            </div>

            <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
              <SingleCapability
                icon={Performance}
                title='Organisational Performance'
              >
                Conduct an in-depth analysis of the organizational structure to
                identify strengths and areas of improvement. Help steer toward
                better efficiency and performance by adopting new tools and
                practices, mentor and guide in-house tech teams.
              </SingleCapability>
            </div>

            <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
              <SingleCapability icon={Sourcing} title='Technology Sourcing'>
                Develop and advise on the optimal supplier sourcing strategy
                that aligns with the business objectives and technology
                priorities. Help to identify, evaluate and select strategic
                suppliers and build a foundation for a productive relationship.
              </SingleCapability>
            </div>

            <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
              <SingleCapability icon={Providers} title='Vendor Management'>
                Ensure seamless sourcing strategy execution by helping the
                organization manage service providers, contributing to improved
                service delivery, enhanced efficiency, and sustainable outcomes.
              </SingleCapability>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Capabilities;
